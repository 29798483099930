import { genShortUrlApi } from "./genShortUrl";
import { getShortUrlApi } from "./getShortUrl";
import { productInfo } from "./getProducts";
import { ApiData } from "./renderAndLoadImage";
import { RenderApi } from "./RenderApi/RenderApi";

export default {
  render: new RenderApi(),
  genShortUrl: new genShortUrlApi(),
  getShortUrlApi: new getShortUrlApi(),
  productInfo: new productInfo(),
};

export const apiData = new ApiData();
