import { AxiosError } from "axios";
import { genDataSceneToSend } from "../../../utils/functions/genDataSceneToSend";
import { getDataStaticEnvironment } from "../../../utils/functions/getDataStaticEnvironment";
import { BaseApi } from "../BaseApi";
import { popUp } from "../../../features/popUp/PopUp";

export class RenderApi extends BaseApi {
  private token: string;

  constructor() {
    const baseURL = "https://vivid-temp-render.3dconfiguration.com/3d/";
    super(baseURL);
    this.token = "zCxGZgV3iVUR93C2Cua2lTgZZZppRL1z32VifmzwdPgcP";

    // Додаємо токен до axios інстансу
    this.axiosInstance.interceptors.request.use((config) => {
      config.headers.Authorization = this.token;
      return config;
    });
  }

  public async renderAndLoadImage(
    body: any,
    closeFunc: () => void
  ): Promise<any> {
    const result = genDataSceneToSend(body);
    const environments = getDataStaticEnvironment();
    result.environment = environments;

    console.log("result: ", result);

    //@ts-ignore
    const stopwatch = window.stopwatch;

    try {
      const { data } = await this.axiosInstance.post(
        "renderConfiguration",
        result
      );

      if (stopwatch) {
        // stopwatch.stop();
        // closeFunc();
        return data;
      }

      return data;
    } catch (err: unknown) {
      if (err instanceof AxiosError) {
        if (stopwatch) {
          // stopwatch.reset();
          // stopwatch.stop();
          setTimeout(() => {
            //@ts-ignore
            popUp({ type: "failure", message: err.message });
            closeFunc();
          }, 500);
        }
        if (err.response) {
          return err.response.data;
        } else {
          return err.message;
        }
      }

      return "Error";
    }
  }

  public getToken() {
    return this.token;
  }
}
