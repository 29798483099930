import { useEffect, useState } from "react";
import ReconnectingWebSocket from "reconnecting-websocket";
import { useDispatch } from "react-redux";
import { ApiData, handleImage } from "../../processes/api/renderAndLoadImage";
import api from "../../processes/api";
import {
  changeShowModal,
  setRenderHash,
  setServerStatus,
  setWsConnected,
} from "../../redux/actions";
import store from "../../redux/store";
import { ProjectService } from "../../services/ProjectService";

function WebSocketComponent() {
  //@ts-ignore
  const [pingInterval, setPingInterval]: any = useState(null);
  //@ts-ignore
  const [pingTimeout, setPingTimeout]: any = useState(null);
  const dispatch = useDispatch();

  let ProjectID = 1;

  try {
    const projectID = ProjectService.getProjectID();
    if (projectID !== null) {
      ProjectID = projectID;
    } else {
      console.error(
        "Project ID not found in window or URL. Default projectID=1 will be used."
      );
    }
  } catch (error: any) {
    console.error(error.message);
  }

  useEffect(() => {
    const socketUrl = `wss://vivid-temp-render.3dconfiguration.com/3d/?token=${api.render.getToken()}`;
    // const socketUrl = `wss://renderapi-3d.vivid3d.tech/?token=zCxGZgV3iVUR93C2Cua2lTgZZZppRL1z32VifmzwdPgcP`;
    let reconnectAttempts = 0;
    const maxReconnectAttempts = 5;
    const baseReconnectInterval = 1000;

    const createWebSocket = () => {
      const socket = new ReconnectingWebSocket(socketUrl);
      socket.onopen = () => {
        console.log("Соединение установлено");
        dispatch(setWsConnected({ status: true }));
        reconnectAttempts = 0;
        startPingInterval();
      };

      socket.onmessage = (event: any) => {
        const hash = store.getState().ui.renderHash;
        const serverStatus = store.getState().ui.serverStatus;
        const data = JSON.parse(event.data);
        const type = data.type;
        if (type === "pong") {
          if (serverStatus !== data.serverStatus)
            dispatch(setServerStatus({ serverStatus: data.serverStatus }));
          // if (data.serverStatus === "offline" && serverStatus !== data.serverStatus)
          //   dispatch(
          //     changeShowModal({
          //       nameModal: "serverOffline",
          //       stateModal: true,
          //     })
          //   );
          stopPingInterval();
          startPingInterval();
        } else if (data.status === "success") {
          if (hash === data.configHash) {
            dispatch(setRenderHash({ hash: "" }));
            //@ts-ignore
            const stopwatch = window.stopwatch;
            // stopwatch.reset();
            // stopwatch.stop();
            dispatch(
              changeShowModal({
                nameModal: "renderLoader",
                stateModal: false,
                otherParams: { img: data.imageUrl },
              })
            );
            dispatch(
              changeShowModal({
                nameModal: "renderPreview",
                stateModal: true,
                otherParams: { img: data.imageUrl },
              })
            );
            dispatch(
              changeShowModal({ nameModal: "loader", stateModal: false })
            );
          }
        }
      };

      socket.onclose = () => {
        reconnectAttempts++;

        if (reconnectAttempts < maxReconnectAttempts) {
          setTimeout(() => {
            stopPingInterval();
            createWebSocket();
          }, baseReconnectInterval);
        } else {
          console.log(
            "Достигнуто максимальное количество попыток переподключения"
          );
          stopPingInterval();
          socket.close();
        }
        console.log("Соединение закрыто");
      };

      return socket;
    };

    const sendPing = () => {
      if (socket) {
 
        socket.send(JSON.stringify({ type: "ping", projectId: ProjectID }));
 
      }
    };

    const socket: any = createWebSocket();

    const startPingInterval = () => {
      const intervalId = setInterval(sendPing, 4000);
      setPingInterval(intervalId);

      const timeoutId = setTimeout(() => {
        console.log("Сервер не ответил на ping, разрываем соединение");
        stopPingInterval();
        socket.close();
      }, 12000);
      setPingTimeout(timeoutId);
    };
    const stopPingInterval = () => {
      setPingInterval((prev: any) => {
        clearInterval(prev);
        return prev;
      });
      setPingTimeout((prev: any) => {
        clearTimeout(prev);
        return prev;
      });
    };

    return () => {
      // if (socket.readyState === 1) {
      stopPingInterval();
      socket.close();
      // }
    };
  }, []);

  return <></>;
}

export default WebSocketComponent;
